<template>
  <div class="container">
    <div class="wrapper">
      <a-form :form="form" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-divider orientation="left">订单信息</a-divider>
        <a-row>
          <a-col :span="12">
            <a-form-item :colon="false" label="项目名称：">
              <div>{{taskData.task_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="开始时间：">
              <div>{{taskData.start_time | formateDate(true)}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="项目服务费：">
              <div>¥{{taskData.task_money}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="taskData.bing && taskData.bing.length > 0">
            <a-form-item :colon="false" label="结算人数：">
              <div>{{taskData.bing.length}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="项目类型：">
              <div>{{taskData.g_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="税率：">
              <div>{{taskData.tax | formateTax()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="订单编号：">
              <div>{{taskData.order_no}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="taskData.sure_apply_time">
            <a-form-item :colon="false" label="服务商开票时间：">
              <div>{{taskData.sure_apply_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item :colon="false" label="标签：" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }">
          <div><a-tag v-for="item in taskData.lable_name" :key="item.id">{{item.name}}</a-tag></div>
        </a-form-item>
        <a-divider orientation="left">公司信息</a-divider>
        <a-row>
          <a-col :span="12">
            <a-form-item :colon="false" label="公司名称：">
              <div>{{taskData.u_company_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="联系电话：">
              <div>{{taskData.u_phone}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="公司地址：">
              <div>{{taskData.u_address}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider orientation="left">服务商信息</a-divider>
        <a-row>
          <a-col :span="12">
            <a-form-item :colon="false" label="服务商：">
              <div>{{taskData.c_real_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="联系电话：">
              <div>{{taskData.c_phone}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :colon="false" label="公司地址：">
              <div>{{taskData.c_address}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <template v-if="taskData.status > 0">
          <a-divider orientation="left">项目人员信息</a-divider>
          <table style="width: 100%;border:1px solid #e8e8e8;">
            <thead class="ant-table-thead">
              <tr>
                <th>姓名</th>
                <th>手机号码</th>
                <th>服务费用</th>
              </tr>
            </thead>
            <tbody class="ant-table-tbody">
              <tr v-for="item in taskData.bing" :key="item.id">
                <td>{{item.real_name}}</td>
                <td>{{item.phone}}</td>
                <td>{{item.money}}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </a-form>
    </div>
  </div>
</template>

<script>

import { taskDetail } from '@/utils/api'
import { mapState } from 'vuex'
export default {
  name: 'PrintTask',
  data() {
    return {
      labelCol: { lg: { span: 10 }, sm: { span: 10 }, xs: { span: 10 } },
      wrapperCol: { lg: { span: 14 }, sm: { span: 14 }, xs: { span: 14 } },
      form: this.$form.createForm(this),
      taskData: {}
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  filters: {
    formateMoney(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val.toFixed(2)
      }
      return '0.00'
    },
    formateDate(val, noDetail) {
      if (val) {
        const date = new Date(parseInt(val) * 1000)
        const y = date.getFullYear()
        const m = date.getMonth() + 1
        const d = date.getDate()
        const h = date.getHours()
        const i = date.getMinutes()
        const s = date.getSeconds()
        return y + '-'
              + (m < 10 ? '0' + m : m) + '-'
              + (d < 10 ? '0' + d : d) +
              (noDetail ? '' : (' '
              + (h < 10 ? '0' + h : h) + ':'
              + (i < 10 ? '0' + i : i) + ':'
              + (s < 10 ? '0' + s : s)
              ))
      }
      return ''
    },
    formateTax(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val * 100 + '%'
      }
      return ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const id = this.$route.params.id || ''
      taskDetail({
        id
      }).then(res => {
        const data = res.info
        this.taskData = data
        
        const loading = this.$message.loading('加载中...', 0)
        setTimeout(loading, 500)
        setTimeout(() => {
            window.print()
            window.close() 
        }, 1000);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
    width: 210mm;  //210mm×297mm
}
.wrapper {
  padding: 0 24px 24px;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
@media print {
    .wrapper {
        /deep/.ant-col-lg-10 {
            box-sizing: border-box;
            width: 41.66666667%;
            display: inline-block;
            overflow: hidden;
            line-height: 39.9999px;
            white-space: nowrap;
            text-align: right;
            vertical-align: middle;
        }
        /deep/.ant-col-lg-14 {
            display: block;
            box-sizing: border-box;
            width: 58.33333333%;
        }
    }
    @page {
        size: auto;
        margin: 0mm;
    }
}
</style>
